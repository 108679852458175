import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import { makeStyles } from "@material-ui/core/styles"

import FilterSection from "./filterSection"
import OrdersList from "./OrdersList"
import SelectedOrderPanel from "./selectedOrderPanel"
import TableMapCont from "./tableMapCont"
import LocalOrderAdd from "./localOrderAdd"
import ReadyOrderList from "./readyOrderList"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
    paddingTop: "4.5rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "4rem",
    },
  },

  inMainGrid: {
    padding: "0.5rem",
  },

  inMainBox: {
    [theme.breakpoints.only("xl")]: {
      height: "calc(100svh  - 95px)",
    },
  },
  inMainOrdersGrid: {
    [theme.breakpoints.only("xl")]: {
      height: "calc(100svh  - 212px)",
    },
  },
  mainOrdersBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0.5rem",
    marginTop: "0.75rem",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "calc(100svh  - 212px)",
    },
    overflowY: "auto",
  },

  inMainBoxSelectedOrder: {
    [theme.breakpoints.only("xl")]: {
      height: "calc(100svh  - 95px)",
    },
    borderRadius: "0.5rem",
    //borderStyle: "solid",
    //borderWidth: "1px",
    //borderColor: theme.palette.common.blackFour,
    width: "100%",
  },

  tablesMap: {
    [theme.breakpoints.only("xl")]: {
      height: "calc(100svh  - 95px)",
    },
  },
  tablesMapinBox: {
    width: "100%",

    padding: "0.5rem",
    borderRadius: "0.5rem",
    backgroundColor: theme.palette.common.blackTwo,
  },

  localOrderAddGrid: {
    [theme.breakpoints.only("xl")]: {
      height: "calc(100svh  - 465px)",
    },
  },

  readyOrdersBox: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0.5rem",
    marginTop: "0.75rem",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "calc(100svh  - 465px)",
    },
    overflowY: "auto",
  },

  alertTitle: {
    fontSize: "1.75rem !important",
    marginTop: "0.5rem",
    lineHeight: "2rem !important",
  },

  dialogText: {
    fontSize: "1.25rem !important",
    color: theme.palette.common.white,
    lineHeight: "1.5rem",
  },
  alertTitCont: {
    paddingBottom: 0,
  },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  butDilog: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  alertPaper: {
    backgroundColor: theme.palette.common.redSelected,
  },
}))

const MainAdminInCont = ({
  user,
  printer,
  dispatchFeedback,
  setAlarm,
  storeSettings,
  kitchenSettings,
  ordersIn,
  selectedOrder,
  setSelectedOrder,
  setOrdersIn,
  localOrdersIn,
  setLocalOrdersIn,
  readyOrders,
}) => {
  const [selectedOrderTypeOption, setSelectedOrderTypeOption] = React.useState({
    value: "anyType",
    label: "Any Type",
  })
  const [selectedOrderStateOption, setSelectedOrderStateOption] =
    React.useState({ value: "anyState", label: "Any State" })
  const [isMoreActionVisible, setIsMoreActionVisible] = React.useState(false)
  const [selectedTable, setSelectedTable] = React.useState(null)
  const [searchNum, setSearchNum] = React.useState(0)
  const [open, setOpen] = React.useState(false)
  const [printAlert, setPrintAlert] = React.useState("")
  const [isPrinterLoading, setIsPrinterLoading] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    setPrintAlert("")
  }

  React.useEffect(() => {
    if (printAlert !== "") {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [printAlert])

  const classes = useStyles()

  //console.log(ordersIn)

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        classes={{ root: classes.mainContainer }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={4}
          classes={{ root: classes.inMainGrid }}
        >
          <Box classes={{ root: classes.inMainOrdersGrid }}>
            <FilterSection
              selectedOrderTypeOption={selectedOrderTypeOption}
              setSelectedOrderTypeOption={setSelectedOrderTypeOption}
              selectedOrderStateOption={selectedOrderStateOption}
              setSelectedOrderStateOption={setSelectedOrderStateOption}
              searchNum={searchNum}
              setSearchNum={setSearchNum}
              selectedTable={selectedTable}
              setSelectedTable={setSelectedTable}
              storeSettings={storeSettings}
              setSelectedOrder={setSelectedOrder}
              ordersIn={ordersIn}
              user={user}
              setAlarm={setAlarm}
              setOrdersIn={setOrdersIn}
              kitchenSettings={kitchenSettings}
              dispatchFeedback={dispatchFeedback}
            />
            <Box classes={{ root: classes.mainOrdersBox }}>
              <OrdersList
                selectedOrderTypeOption={selectedOrderTypeOption}
                selectedOrderStateOption={selectedOrderStateOption}
                searchNum={searchNum}
                selectedTable={selectedTable}
                ordersIn={ordersIn}
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                kitchenSettings={kitchenSettings}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={2}
          classes={{ root: classes.inMainGrid }}
        >
          <Box classes={{ root: classes.inMainBoxSelectedOrder }}>
            <SelectedOrderPanel
              setPrintAlert={setPrintAlert}
              isPrinterLoading={isPrinterLoading}
              setIsPrinterLoading={setIsPrinterLoading}
              user={user}
              printer={printer}
              dispatchFeedback={dispatchFeedback}
              setAlarm={setAlarm}
              ordersIn={ordersIn}
              setOrdersIn={setOrdersIn}
              selectedOrder={selectedOrder}
              isMoreActionVisible={isMoreActionVisible}
              setIsMoreActionVisible={setIsMoreActionVisible}
              kitchenSettings={kitchenSettings}
              setSelectedOrder={setSelectedOrder}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={4}
          classes={{ root: classes.inMainGrid }}
        >
          <Box classes={{ root: classes.tablesMap }}>
            <Box classes={{ root: classes.tablesMapinBox }}>
              <TableMapCont
                ordersIn={ordersIn}
                selectedOrder={selectedOrder}
                storeSettings={storeSettings}
                setSelectedOrderTypeOption={setSelectedOrderTypeOption}
                setSelectedOrderStateOption={setSelectedOrderStateOption}
                setSelectedTable={setSelectedTable}
                setSelectedOrder={setSelectedOrder}
                selectedTable={selectedTable}
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={2}
          classes={{ root: classes.inMainGrid }}
        >
          <Box classes={{ root: classes.localOrderAddGrid }}>
            <LocalOrderAdd
              user={user}
              dispatchFeedback={dispatchFeedback}
              localOrdersIn={localOrdersIn}
              setLocalOrdersIn={setLocalOrdersIn}
            />
            <Box classes={{ root: classes.readyOrdersBox }}>
              <ReadyOrderList
                ordersIn={ordersIn}
                localOrdersIn={localOrdersIn}
                user={user}
                dispatchFeedback={dispatchFeedback}
                setAlarm={setAlarm}
                setOrdersIn={setOrdersIn}
                selectedOrder={selectedOrder}
                kitchenSettings={kitchenSettings}
                setSelectedOrder={setSelectedOrder}
                setLocalOrdersIn={setLocalOrdersIn}
                readyOrders={readyOrders}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby={`printer-error-main-title`}
        aria-describedby={`printer-error-main-description`}
        classes={{ paper: classes.alertPaper }}
      >
        <DialogTitle
          id={`printer-error-main-title`}
          classes={{ root: classes.alertTitCont }}
        >
          <Box classes={{ root: classes.alertTitle }}>{"Printer Error"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{ root: classes.dialogText }}
            id={`printer-error-main-description`}
          >
            {`${printAlert}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            classes={{ root: classes.butGridCont }}
          >
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleClose}
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                fullWidth
                classes={{ root: classes.butDilog }}
              >
                <Typography variant="body1">{"OK"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MainAdminInCont
