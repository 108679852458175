import * as React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"

import MoreActionsSection from "./moreActionsSection"
import OrderItemLine from "./orderItemLine"
import { mainPrintImport, sectionPrint } from "../helperFunc/mainPrintFilter"
import handleError from "../../../helperFunc/handleError"

import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import { makeStyles } from "@material-ui/core/styles"
import getHrMinText from "../helperFunc/getHrMinText"
import getTimeAgo from "../helperFunc/getTimeAgo"
import priceFormat from "../../../helperFunc/priceFormat"

const useStyles = makeStyles(theme => ({
  orderListBox: {
    width: "100%",
  },
  subHeading: {
    fontSize: "1.25rem",
  },
  orderTypeChip: {
    marginRight: "0.5rem",
    height: "1.75rem",
    padding: 0,
  },

  priceChip: {
    height: "1.5rem",
    backgroundColor: theme.palette.common.black,
  },

  idChip: {
    height: "2rem",
    backgroundColor: theme.palette.common.black,
  },

  idText: {
    fontSize: "1.25rem !important",
    lineHeight: "1.25rem !important",
  },

  chipsBox: {
    marginBottom: "0.75rem",
  },

  infoBox: {
    marginBottom: "0.5rem",
  },
  orderTypeChipDineIn: {
    backgroundColor: `${theme.palette.common.dineIn} !important`,
  },

  orderListGrid: { width: "100%" },
  statusChip: {
    height: "1.75rem",
  },
  statusBoxReceived: {
    backgroundColor: `${theme.palette.common.received} !important`,
  },
  statusBoxPreparing: {
    backgroundColor: `${theme.palette.common.preparing} !important`,
  },
  statusBoxReady: {
    backgroundColor: `${theme.palette.common.ready} !important`,
  },
  statusBoxDone: {
    backgroundColor: `${theme.palette.common.done} !important`,
  },

  statusText: {
    fontWeight: 700,
    color: theme.palette.common.offWhite,
    textTransform: "uppercase",
  },

  mainInGridCont: {
    height: "calc(100svh - 305px)",
    minHeight: "30rem",
    flexWrap: "nowrap",
    overflow: "auto",
    transition: "all 0.25s",
  },

  mainInGridContSmaller: {
    height: "calc(100svh - 405px)",
    transition: "all 0.25s",
  },

  actionsGrid: {
    marginBottom: "0.5rem",
  },

  expandMoreIcon: {
    rotate: "0deg",
    transition: "all 0.25s",
  },

  expandMoreIconInv: {
    rotate: "180deg",
    transition: "all 0.25s",
  },

  moreActionsBox: {
    backgroundColor: theme.palette.common.black,
    borderRadius: "0.5rem",
    maxHeight: 0,
    transition: "all 0.25s",
    marginBottom: 0,
    overflow: "hidden",
  },
  moreActionsBoxVis: {
    maxHeight: "5.75rem",
    marginBottom: "0.5rem",
    transition: "all 0.25s",
  },

  inGrid: {
    width: "100%",
    minHeight: "3.4rem",
    backgroundColor: theme.palette.common.black,
    borderRadius: "0.6rem",
    marginBottom: "0.5rem",
    overflow: "auto",
    position: "relative",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
  },

  headerText: {
    color: theme.palette.common.orange,
    fontSize: "1.25rem",
    lineHeight: "1.25rem",
  },

  ordText: {
    fontSize: "1rem",
    lineHeight: "1.1rem",
    overflowWrap: "anywhere",
  },
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 120px)",
    position: "relative",
  },
  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },

  icon: {
    color: theme.palette.common.blackNine,
    fontSize: "3rem !important",
  },
}))

const SelectedOrderPanel = ({
  selectedOrder,
  isMoreActionVisible,
  setIsMoreActionVisible,
  user,
  dispatchFeedback,
  setAlarm,
  setOrdersIn,
  kitchenSettings,
  ordersIn,
  setSelectedOrder,
  printer,
  setPrintAlert,
  isPrinterLoading,
  setIsPrinterLoading,
}) => {
  const classes = useStyles()

  const handlePrint = () => {
    if (printer.current) {
      setIsPrinterLoading(true)
      let printsPerBatch = 5
      let printFailed = false
      let printData = mainPrintImport(
        { ...selectedOrder },
        kitchenSettings.sections
      )

      printer.current.onreceive = function (res) {
        if (res.success) {
          let printjobid = res.printjobid
          let printjobidarray = printjobid.split("BATCH")[1].split("COUNT")
          let batchNo = parseInt(printjobidarray[0])
          let count = parseInt(printjobidarray[1])
          let totalPrintsBatch = Math.ceil(
            printData[count].items.length / printsPerBatch
          )

          if (batchNo >= totalPrintsBatch && count + 1 >= printData.length) {
            setIsPrinterLoading(false)
          } else if (batchNo < totalPrintsBatch) {
            sectionPrint(
              selectedOrder,
              printData[count],
              printer,
              printsPerBatch,
              batchNo + 1,
              count
            )
          } else if (count < printData.length) {
            sectionPrint(
              selectedOrder,
              printData[count + 1],
              printer,
              printsPerBatch,
              1,
              count + 1
            )
          }
        }

        if (!res.success) {
          if (!printFailed) {
            printFailed = true

            setIsPrinterLoading(false)

            setPrintAlert(res.code)
          }
        }
        //console.log(res, successPrints)
      }

      sectionPrint(selectedOrder, printData[0], printer, printsPerBatch, 1, 0)
    } else {
      handleError("Not connected to printer", dispatchFeedback)
    }
  }

  //console.log(selectedOrder)
  return (
    <>
      {Object.keys(selectedOrder).length > 0 ? (
        <Box classes={{ root: classes.orderListBox }}>
          {selectedOrder.orderType === "dineIn" ? (
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Typography variant="h4">
                    {`TBL ${selectedOrder.orderTableNumber}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Chip
                    classes={{ root: classes.idChip }}
                    label={
                      <Typography
                        variant="body1"
                        classes={{ root: classes.idText }}
                      >
                        {`#${selectedOrder.id}`}
                      </Typography>
                    }
                  ></Chip>
                </Grid>
              </Grid>

              <Typography
                variant="h5"
                classes={{ root: classes.subHeading }}
              >{`${getTimeAgo(selectedOrder.orderTime, true)}`}</Typography>
            </>
          ) : selectedOrder.orderType === "takeAway" ? (
            <>
              <Typography variant="h4">{`#${selectedOrder.id}`}</Typography>
              <Typography variant="h5" classes={{ root: classes.subHeading }}>
                {`${getHrMinText(selectedOrder.orderPickUpTime)} ${
                  selectedOrder.orderPickUpDate.label
                }`}
              </Typography>
            </>
          ) : (
            <></>
          )}

          <Box classes={{ root: classes.chipsBox }}>
            <Chip
              classes={{
                root: clsx(classes.orderTypeChip, {
                  [classes.orderTypeChipDineIn]:
                    selectedOrder.orderType === "dineIn",
                }),
              }}
              variant={"outlined"}
              label={
                selectedOrder.orderType === "takeAway" ? (
                  <Typography
                    variant="body1"
                    classes={{ root: classes.statusText }}
                  >
                    {"TAKE AWAY"}
                  </Typography>
                ) : selectedOrder.orderType === "dineIn" ? (
                  <Typography
                    variant="body1"
                    classes={{ root: classes.statusText }}
                  >
                    {"DINE-IN"}
                  </Typography>
                ) : (
                  ""
                )
              }
            ></Chip>

            <Chip
              classes={{
                root: clsx(classes.statusChip, {
                  [classes.statusBoxReceived]:
                    selectedOrder.status === "Received",
                  [classes.statusBoxPreparing]:
                    selectedOrder.status === "Preparing",
                  [classes.statusBoxReady]: selectedOrder.status === "Ready",
                  [classes.statusBoxDone]: selectedOrder.status === "Done",
                }),
              }}
              label={
                <Typography
                  variant="body1"
                  classes={{ root: classes.statusText }}
                >{`${selectedOrder.status}`}</Typography>
              }
            ></Chip>
          </Box>

          <Typography variant="h6">
            {`${selectedOrder.users_permissions_user?.userDisplayName}`}
          </Typography>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
            classes={{ root: classes.infoBox }}
          >
            <Grid item>
              <Typography variant="body1">
                {`${selectedOrder.users_permissions_user?.phone}`}
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                classes={{ root: classes.priceChip }}
                label={
                  <Typography variant="body1">
                    {`${priceFormat(selectedOrder.total)}`}
                  </Typography>
                }
              ></Chip>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            classes={{ root: classes.actionsGrid }}
          >
            <Grid item>
              <Button
                disabled={isPrinterLoading}
                onClick={handlePrint}
                disableElevation
                variant="contained"
                size="large"
                startIcon={
                  isPrinterLoading ? (
                    <CircularProgress color="inherit" size={"1.35rem"} />
                  ) : (
                    <LocalPrintshopOutlinedIcon />
                  )
                }
              >
                <Typography variant="body1">{"PRINT"}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={e => {
                  setIsMoreActionVisible(!isMoreActionVisible)
                }}
                disabled={!kitchenSettings.mainMoreActionVisibleDef}
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                endIcon={
                  <ExpandMoreOutlinedIcon
                    classes={{
                      root: clsx(classes.expandMoreIcon, {
                        [classes.expandMoreIconInv]: isMoreActionVisible,
                      }),
                    }}
                  />
                }
              >
                <Typography variant="body1">{"SET ORD STATUS"}</Typography>
              </Button>
            </Grid>
          </Grid>
          <Box
            classes={{
              root: clsx(classes.moreActionsBox, {
                [classes.moreActionsBoxVis]: isMoreActionVisible,
              }),
            }}
          >
            <MoreActionsSection
              selectedOrder={selectedOrder}
              user={user}
              dispatchFeedback={dispatchFeedback}
              setAlarm={setAlarm}
              setOrdersIn={setOrdersIn}
              kitchenSettings={kitchenSettings}
              ordersIn={ordersIn}
              setSelectedOrder={setSelectedOrder}
              printer={printer}
              setPrintAlert={setPrintAlert}
              isPrinterLoading={isPrinterLoading}
              setIsPrinterLoading={setIsPrinterLoading}
            />
          </Box>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            classes={{
              root: clsx(classes.mainInGridCont, {
                [classes.mainInGridContSmaller]: isMoreActionVisible,
              }),
            }}
          >
            {selectedOrder.orderNotes ? (
              <Grid item classes={{ root: classes.inGrid }}>
                <Typography variant="h6" classes={{ root: classes.headerText }}>
                  {"ORDER NOTES"}
                </Typography>
                <Typography variant="body1" classes={{ root: classes.ordText }}>
                  {selectedOrder.orderNotes}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}

            {selectedOrder.sections.map((section, index) =>
              section.sectionHasItems ? (
                <OrderItemLine
                  key={`${selectedOrder.id}-${index}`}
                  selectedOrder={selectedOrder}
                  section={section}
                />
              ) : null
            )}
          </Grid>
        </Box>
      ) : (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <InfoOutlinedIcon
              classes={{ root: classes.icon }}
            ></InfoOutlinedIcon>
            <Typography variant="body2">{"No Order Selected"}</Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default SelectedOrderPanel
